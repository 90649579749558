
.qrcode-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LiveDebug {
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    background-color: #0e1122;
    overflow: hidden;
    #containers {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-bottom: 5%;
        overflow: hidden;
        header {
            padding: 30px;
            margin-bottom: 10px;
            font-size: 24px;
            color: #8593C7;
        }
        main {
            flex: 1;
            box-sizing: border-box;
            display: flex;
            width: 80%;
            overflow: hidden;
            .camera {
                position: relative;
                width: 488px;
                height: 100%;
                background: url("~assets/images/live_background.png") no-repeat;
                background-size: 100% 100%;
                border-radius: 8px;
                overflow: hidden;
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .volume-operation {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin-left: 40px;
                .volume {
                    flex: 60;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 40px;
                    overflow: hidden;
                    .title {
                        width: 100%;
                        margin-bottom: 20px;
                        font-size: 18px;
                        text-align: center;
                        color: #91A1D9;
                    }
                    .content {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #192037;
                        border-radius: 16px;
                    }
                }
                .operation {
                    flex: 17.8;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    background-color: #192037;
                    border-radius: 16px;
                    overflow: hidden; 
                    .start,
                    .end {
                        width: 128px;
                        height: 40px;
                        color: #fff;
                        border: none;
                        border-radius: 8px;
                        overflow: hidden;
                    }  
                    .start {
                        background-image: linear-gradient(90deg, #6D94FE 0%, #3C64FF 100%);
                    }
                    .end {
                        background-image: linear-gradient(90deg, #FE6D6D 0%, #FF3C3C 100%);
                    }
                }
            }
            
        }
    }
}
